import React,{ Component } from 'react';
import { StyleSheet, Text, View, SafeAreaView, FlatList, StatusBar,Image} from "react-native";
import { Avatar, Button, Card, Icon, Paragraph } from 'react-native-elements';
import { FlatGrid,SectionGrid } from 'react-native-super-grid';
import {GetRecipes} from '../helpers/DataAccess';
import AsyncStorage from '@react-native-async-storage/async-storage';
const recipesData = [];
export default class RecipesList extends Component {
    
    async componentDidMount() {

      let obj = await AsyncStorage.getItem("surdejlig_recipes");
      let jsonData = JSON.parse(obj);      
      for(var i in jsonData) {
        
        recipesData.push(jsonData[i]);
      }
    }
    render() {
        return (
            <SafeAreaView style={styles.container}>
              <SectionGrid
                itemDimension={200}
                sections={[
                  {
                    title: 'Mine Opskrifter',
                    data: recipesData,
                  },
                  {
                    title: 'Online Opskrifter',
                    data: [],
                  },
                ]}
                renderItem={renderItem}
                renderSectionHeader={({ section }) => (
                  <Text style={{ fontSize: 20 }}>{section.title}</Text>
                )}
              />
            </SafeAreaView>
          );
    }
}
const renderItem = ({ item }) => (
  <Card style={{
    flex: 1,
    flexDirection: 'column',
    margin: 1
  }}>
  <Card.Title>{item.recipeName}</Card.Title>
  <Card.Divider/>
  <Card.Image source={item.imgUrl}></Card.Image>
  <Text style={{marginBottom: 10}}>
    {item.desc}
    </Text>
  <Button
      //icon={<Icon name='code' color='#ffffff' />}
      buttonStyle={{borderRadius: 0, marginLeft: 0, marginRight: 0, marginBottom: 0}}
      title='Se opskrift' />
</Card>
);
const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginTop: StatusBar.currentHeight || 0,
    },
    desc:{
      fontWeight: "600",
    },
  });