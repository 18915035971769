import React,{ Component } from 'react';
import { StyleSheet, Text, View ,TouchableOpacity,Icon} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
class TopBar extends Component {
    onPress = () => {
        this.props.props.current.navigate('bread-calc')
    };
    render() {
        return (
            
        <View style={styles.container}>
            <Text>Left</Text>
            <Text>TopBar</Text>
            <TouchableOpacity
                onPress={this.onPress}
                style={styles.roundButton1}>
                <MaterialCommunityIcons name="playlist-plus" color={"white"} size={30} ></MaterialCommunityIcons>
            </TouchableOpacity>
        </View>
        );
    }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    height: 56,
    flexDirection: 'row', // row
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between', // center, space-around
    paddingLeft: 10,
    paddingRight: 10
  },
  roundButton1: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 50,
    backgroundColor: '#03a9f4',
    cursor: "pointer"
  },
});

export default TopBar;