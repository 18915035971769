import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import Tabmenu from './components/Tabmenu'
import TopBar from './components/TopBar';


export default function App() {
  const ref = React.useRef(null);
  return (
    
     <NavigationContainer ref={ref}>
       {/* <TopBar props={ref}/> */}
      <Tabmenu />
    </NavigationContainer>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
