import React,{ Component } from 'react';
import {StyleSheet, Text, View} from "react-native";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import RecipesList from '../screens/RecipesList';
import RecipesFavorites from '../screens/RecipesFavorites';
import BreadCalc from '../screens/BreadCalc';
import NewRecipe from '../screens/NewRecipe';
const Tab = createBottomTabNavigator();
export default class Tabmenu extends Component {
    render() {
            return (
              <Tab.Navigator
                initialRouteName="RecipesList"
                tabBarOptions={{
                  activeTintColor: '#e91e63',
                }}
              >
                <Tab.Screen
                  name="RecipesList"
                  component={RecipesList}
                  options={{
                    tabBarLabel: '',
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons name="home" color={color} size={size} />
                    ),
                  }}
                />
                   <Tab.Screen
                  name="NewRecipe"
                  component={NewRecipe}
                  options={{
                    tabBarLabel: '',
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons name="playlist-plus" color={color} size={size} />
                    ),
                  }}
                />
                <Tab.Screen
                  name="recipes-favorites"
                  component={RecipesFavorites}
                  options={{
                    tabBarLabel: '',
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons name="heart" color={color} size={size} />
                    ),
                  }}
                />
               
                <Tab.Screen
                  name="bread-calc"
                  component={BreadCalc}
                  options={{
                    tabBarLabel: '',
                    tabBarIcon: ({ color, size }) => (
                      <MaterialCommunityIcons name="calculator" color={color} size={size} />
                    ),
                  }}
                />
              </Tab.Navigator>
            );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5FCFF',
    },
});