import React,{ Component } from 'react';
import {StyleSheet, TextInput , View,TouchableOpacity} from "react-native";

export default class NewRecipe extends Component {
    state = {
        recipeName: '',
     }
     handleRecipeName = (text) => {
        this.setState({ recipeName: text })
     }
    render() {
        return (
            <View style = {styles.container}>
            <TextInput style = {styles.input}
               underlineColorAndroid = "transparent"
               placeholder = "Navn på opskrift"
               placeholderTextColor = "#9a73ef"
               autoCapitalize = "none"
               onChangeText = {this.handleRecipeName}/>
         </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        backgroundColor: '#F5FCFF',
        paddingTop:23
    },
    input: {
        margin: 15,
        height: 40,
        borderColor: '#7a42f4',
        borderWidth: 1
     },
});